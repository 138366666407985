import type { FilterGroupMultiSelect, FilterOptionMultiSelect } from "~~/models/filters";
import type { CatalogSearchResponse, CatalogSearchResponsePropertyFilter, CatalogSearchResponsePropertyFilterValue } from "~~/models/search";
import type { ProductSearchPropertyValueDto } from "~~/server/api/ecom/search/index.post";
import { PropertyValueType } from "~~/server/plankton/schema";

type PropertyFilterGroupType = FilterGroupMultiSelect;

export async function mapToPropertyFilters(
  response: CatalogSearchResponse,
  selectedPropertyFilters: Array<ProductSearchPropertyValueDto>,
  hiddenPropertyIdList: Array<number>,
  locale: string,
): Promise<Array<PropertyFilterGroupType>> {
  const filterGroups = [] as Array<PropertyFilterGroupType>;

  if (!response.propertyFilters) {
    return filterGroups;
  }

  // console.log("response.propertyFilters", response.propertyFilters);

  const propertyData = await $fetch("/api/ecom/properties/list");

  for (const propertyFilter of response.propertyFilters) {
    const filterOptions = propertyFilter.values.filter((v: any) => v.count > 0 || v.countActive > 0);

    if (!filterOptions.length) {
      continue;
    }

    const property = propertyData.properties.find(p => p.id === propertyFilter.propertyId);

    if (!property) {
      continue;
    }

    // selected list may only contain selected property filters which are valid for this filter group
    const selectedList = selectedPropertyFilters
      .filter((pv: ProductSearchPropertyValueDto) => filterOptions.map(o => o.propertyValueId).includes(pv.propertyValueId))
      .map((pv: ProductSearchPropertyValueDto) => pv.propertyValueId);

    const filterGroup = {
      id: property.id,
      title: property.translations && property.translations[locale]?.name,
      sortOrder: property.sortOrderValue,
      isHiddenInUI: hiddenPropertyIdList.includes(property.id),
      selectedList: selectedList,
      options: [],
    } as FilterGroupMultiSelect;

    for (let idx = 0; idx < filterOptions.length; idx++) {
      const filterOption = filterOptions[idx];

      filterGroup.options.push({
        label: determineFilterLabel(propertyFilter, filterOption!),
        value: filterOption!.propertyValueId,
        count: filterOption!.count,
        sortOrder: filterOption!.sortOrder,
      } as FilterOptionMultiSelect);
    }

    // sort by order, then by option label
    filterGroup.options.sort((a: FilterOptionMultiSelect, b: FilterOptionMultiSelect) =>
      a.sortOrder - b.sortOrder);

    filterGroups.push(filterGroup);
  }

  // sort by order, then by filter group title
  return filterGroups.sort((a: PropertyFilterGroupType, b: PropertyFilterGroupType) =>
    a.sortOrder - b.sortOrder || a.title.localeCompare(b.title));
}

export function parsePropertyFilters(propertyFilters: string): Array<ProductSearchPropertyValueDto> | undefined {
  try {
    if (!propertyFilters?.length) {
      return undefined;
    }

    return JSON.parse(propertyFilters) as Array<ProductSearchPropertyValueDto>;
  }
  catch (e) {
    console.warn("Invalid property filters in query string", e);
    return undefined;
  }
}

export function parsePropertyFiltersFromCms(propertyFilters: any): Array<ProductSearchPropertyValueDto> | undefined {
  try {
    if (!propertyFilters?.length) {
      return undefined;
    }

    // example...
    // [
    //   {
    //     "name": "Machine > Heftruck",
    //     "value": "{\"propertyId\": 2, \"propertyValueId\": 1}"
    //   }
    // ]

    return propertyFilters.map((pf: any) => JSON.parse(pf.value));
  }
  catch (e) {
    console.warn("Invalid property filters in DatoCMS", e);
    return undefined;
  }
}

function determineFilterLabel(propertyFilter: CatalogSearchResponsePropertyFilter, filterOption: CatalogSearchResponsePropertyFilterValue) {
  switch (propertyFilter!.valueType) {
    case PropertyValueType.Boolean:
      return filterOption!.booleanValue;
    case PropertyValueType.NumericValue:
      return filterOption!.numericValue;
    default:
      return filterOption!.textValue;
  }
}
